<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
            :items="crumbs"
            class="capitalize"
            style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn href="https://www.umohe.com" icon target="_blank" v-on="on">
              <v-icon color="primary" size="16">fa-home</v-icon>
            </v-btn>
          </template>
          <span>Go home</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body dash">
      <v-row>
        <v-col cols="12" lg="5">
          <v-card color="rgb(34, 142, 255)" dark flat min-height="240">
            <v-overlay :value="overUser" absolute></v-overlay>
            <v-toolbar color="rgb(34, 142, 255)" dark flat>
              <v-toolbar-title style="color: white">
                Welcome back, {{ myStatus.fullname }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn :to="{ name: 'medics.appointment' }" color="" icon>
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="padding-bottom: 16px">
              <v-row>
                <v-col class="split">
                  <v-card color="rgb(34, 142, 255)" flat>
                    <v-card-title>
                      <div class="statitle">appointments</div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <div class="statext">{{ myStatus.appointments }}</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card color="rgb(34, 142, 255)" flat>
                    <v-card-title>
                      <div class="statitle">requests</div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <div class="statext">{{ myStatus.requests }}</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card flat height="240">
            <v-overlay :value="overAppoint" absolute></v-overlay>
            <v-toolbar flat>
              <v-toolbar-title>on Appointments</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      :to="{ name: 'medics.appointment' }"
                      color="primary"
                      icon
                      v-on="on"
                  >
                    <v-icon>
                      {{
                        appointmentList.length > 3
                            ? "mdi-link-plus"
                            : "mdi-link"
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{
                    appointmentList.length > 3
                        ? "More than 3 Appointments now"
                        : "Appointments"
                  }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="padding: 0px 16px">
              <v-overlay :value="overTreat" absolute></v-overlay>
              <v-list dense style="padding: 0px">
                <v-list-item-group color="info">
                  <v-list-item v-for="item in limitedItems" :key="item.id">
                    <v-list-item-avatar color="rgb(240, 248, 254)" size="35">
                      <v-img :src="item.picture"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                          v-text="item.fullname"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card flat>
            <v-overlay :value="overTreat" absolute></v-overlay>
            <v-sheet
                color="#f0f8fe"
                elevation="6"
                height="240"
                rounded
                style="padding: 10px"
            >
              <v-toolbar color="#f0f8fe" flat>
                <v-toolbar-title>
                  <v-btn depressed fab large>
                    <v-icon color="indigo" size="64"> mdi-heart-pulse</v-icon>
                  </v-btn>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="chartitle">
                  Treatments
                  <span class="sub">Last 7 Activities</span>
                </div>
              </v-toolbar>

              <apexcharts
                  :options="treatment.treatmentOptions"
                  :series="treatment.treatmentSeries"
                  height="160"
                  width="100%"
              ></apexcharts>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-card flat>
            <v-overlay :value="overEarn" absolute></v-overlay>
            <v-toolbar flat>
              <v-toolbar-title class="earn_title">
                Earning Reports
                <div class="sub">Weekly Earnings this month</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="12" style="padding: 35px 0px 0px 16px">
                  <div style="font-size: 2.2em; font-family: 'Merriweather'">
                    {{ comma(earnings.earningValue) }}
                  </div>
                  <div style="padding: 8px 0px 0px 0px; font-size: 0.85em">
                    You informed of this week compared to last week
                  </div>
                </v-col>
              </v-row>
              <v-card flat style="margin: 16px 0px">
                <v-card-text style="padding: 16px">
                  <apexcharts
                      :options="earnings.earningOption"
                      :series="earnings.earningSeries"
                      height="200"
                      type="bar"
                  ></apexcharts>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card flat>
            <v-overlay :value="overClient" absolute></v-overlay>
            <v-toolbar flat>
              <v-toolbar-title class="earn_title">
                My Appointments
                <div class="sub">This month appointments</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-card :loading="loadevent" flat style="padding: 0; height: 342px">
                <v-date-picker
                    v-model="today"
                    :allowed-dates="getAllowedDates"
                    :events="arrayEvents"
                    :min="isNow"
                    event-color="#2196f3"
                    full-width
                    landscape
                ></v-date-picker>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import VueApexCharts from "vue-apexcharts";
import utils from "../../mixins/utils";

export default {
  components: {apexcharts: VueApexCharts},
  mixins: [utils, WinScroll("position")],
  data() {
    return {
      loading: false,
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: {name: "medics.dash"},
        },
      ],
      knowledge: 33,
      overUser: false,
      overAppoint: false,
      overTreat: false,
      overEarn: false,
      overClient: false,
      myStatus: {},
      appointmentList: [],
      arrayEvents: [],
      limit: 3,
      treatment: {
        treatmentList: [],
        treatmentOptions: null,
        treatmentSeries: null,
      },
      earnings: {
        earningValue: 2150,
        earningList: [
          {day: "13th", value: 150},
          {day: "20th", value: 480},
          {day: "27th", value: 0},
          {day: "4th", value: 530},
          {day: "11th", value: 150},
          {day: "18th", value: 350},
          {day: "25th", value: 160},
        ],
      },
      frequency: {
        series: null,
        options: null,
        frequencyValue: 15,
        frequencyList: [],
      },
      isNow: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
    limitedItems() {
      return this.appointmentList.slice(0, this.limit);
    },
  },
  created() {
    this.workInfo();
    this.appointments();
    this.myTreatments();
    this.myEarning();
    this.myFrequency();
    this.availableDays();
  },
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
    workInfo() {
      let self = this;
      Restful.dash.medic
          .status()
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.myStatus = response.data;
              console.log("myStatus:", this.myStatus);
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
          });
    },
    appointments() {
      let self = this;
      Restful.dash.medic
          .appointments()
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.appointmentList = response.data;
              console.log("myStatus:", this.appointmentList);
            } else {
              this.appointmentList = [];
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
          });
    },
    myTreatments() {
      let self = this;
      Restful.dash.medic
          .treatments()
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.treatment.treatmentList = response.data;
              this.treatmentChart();
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
          });
    },
    treatmentChart() {
      let myDate = this.treatment.treatmentList.map((w) => w.day);
      let myValue = this.treatment.treatmentList.map((w) => w.count);

      (this.treatment.treatmentSeries = [
        {
          name: "Date",
          data: myValue,
        },
      ]),
          (this.treatment.treatmentOptions = {
            chart: {
              type: "line",
              toolbar: {
                show: false,
              },
              events: {
                mounted: (chart) => {
                  chart.windowResizeHandler();
                },
              },
            },
            yaxis: {
              showAlways: false,
              labels: {
                show: false,
              },
              lines: {
                show: false,
              },
            },
            xaxis: {
              categories: myDate,
            },
            fill: {
              opacity: 0.7,
              type: "area",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
              },
            },
            responsive: [
              {
                breakpoint: 800,
                options: {
                  plotOptions: {
                    line: {
                      horizontal: false,
                    },
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
            stroke: {
              curve: "smooth",
            },
          });
    },
    myEarning() {
      let self = this;
      Restful.dash.medic
          .earning()
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.earnings.earningList = response.data.list;
              this.earnings.earningValue = response.data.total;
              this.earningChart();
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
          });
    },
    earningChart() {
      let day = this.earnings.earningList.map((w) => w.day);
      let value = this.earnings.earningList.map((w) => w.value);

      (this.earnings.earningOption = {
        chart: {
          toolbar: {
            show: false,
          },
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
        },
        fill: {
          opacity: 0.9,
          type: "area",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        responsive: [
          {
            breakpoint: 800,
            options: {
              plotOptions: {
                line: {
                  horizontal: false,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        xaxis: {
          categories: day,
        },
      }),
          (this.earnings.earningSeries = [
            {
              name: "Value",
              data: value,
            },
          ]);
    },
    myFrequency() {
      let self = this;
      Restful.dash.medic
          .requests()
          .then((response) => {
            console.log('response', response);
            if (response.status === 200) {
              this.frequency.frequencyList = response.data.list;
              this.frequency.frequencyValue = response.data.total;
              this.frequencyChart();
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
            self.loading = false;
          });
    },
    frequencyChart() {
      let day = this.frequency.frequencyList.map((w) => w.day);
      let value = this.frequency.frequencyList.map((w) => w.count);

      (this.frequency.options = {
        chart: {
          toolbar: {
            show: false,
          },
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
          },
        },
        fill: {
          opacity: 0.9,
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        responsive: [
          {
            breakpoint: 800,
            options: {
              plotOptions: {
                line: {
                  horizontal: false,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        xaxis: {
          categories: day,
        },
      }),
          (this.frequency.series = [
            {
              name: "Value",
              data: value,
            },
          ]);
    },
    getAllowedDates(val) {
      return this.arrayEvents.indexOf(val) !== -1;
    },
    availableDays() {
      this.loadevent = true;
      let self = this;
      Restful.subscribers.available
          .myAvailableDays()
          .then((response) => {
            console.log(response.data);
            // console.log("m:", m);
            this.arrayEvents = response.data.split(",");
            this.loadevent = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loadevent = false;
          });
    },
  },
};
</script>
